const Footer = () => {
  return (
    <p className="clearfix mb-0">
      <span className="float-md-start d-block d-md-inline-block mt-25">
        Copyright © {new Date().getFullYear()}{" "}
        <a href="https://cofoce.guanajuato.gob.mx/" target="_blank" rel="noopener noreferrer">
          COFOCE
        </a>
        {' - '}
        <span className="d-none d-sm-inline-block">
          <a href="https://cofoce.guanajuato.gob.mx/aviso-de-privacidad/" target='_blank'>
            Aviso de privacidad
          </a>
        </span>
      </span>
    </p>
  )
}

export default Footer
